.list-link {
    position: relative;
}

.list-paper {
    display: none;
    position: absolute;
    z-index: 10;
    width: 10em;
    top: 3em;
    right: -24px;
}

/*.list-link:hover  .list-paper {
    display: inline-block;
}*/

.list-open:hover + .list-paper {
    display: inline-block;
}

.list-paper:hover {
    display: inline-block;
}